// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-title {
  font-size: 16px;
  font-weight: 600;
}

.event-title--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-title--wrapper span {
  font-size: 12px;
}

.unset_rotate {
  transform: unset !important;
  -webkit-transform: unset !important;
  -moz-transform: unset !important;
}
.unset_rotate ._output_canvas {
  transform: unset !important;
  -webkit-transform: unset !important;
  -moz-transform: unset !important;
}
.unset_rotate #_webcam {
  transform: unset !important;
  -webkit-transform: unset !important;
  -moz-transform: unset !important;
}
.unset_rotate .code-outline-highlight {
  transform: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/drone-event-camera/drone-event-camera.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,2BAAA;EACA,mCAAA;EACA,gCAAA;AACF;AAAE;EACE,2BAAA;EACA,mCAAA;EACA,gCAAA;AAEJ;AAAE;EACE,2BAAA;EACA,mCAAA;EACA,gCAAA;AAEJ;AACE;EAKE,2BAAA;AACJ","sourcesContent":[".event-title {\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.event-title--wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.event-title--wrapper span {\n  font-size: 12px;\n}\n\n.unset_rotate {\n  transform: unset !important;\n  -webkit-transform: unset !important;\n  -moz-transform: unset !important;\n  ._output_canvas {\n    transform: unset !important;\n    -webkit-transform: unset !important;\n    -moz-transform: unset !important;\n  }\n  #_webcam {\n    transform: unset !important;\n    -webkit-transform: unset !important;\n    -moz-transform: unset !important;\n  }\n\n  .code-outline-highlight {\n    -moz-transform: unset !important;\n    -webkit-transform: unset !important;\n    -o-transform: unset !important;\n    -ms-transform: unset !important;\n    transform: unset !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
