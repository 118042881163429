// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-title {
  font-size: 14px !important;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/event-title/event-title.scss"],"names":[],"mappings":"AAAA;EACC,0BAAA;EACA,gBAAA;AACD","sourcesContent":[".event-title {\n\tfont-size: 14px !important;\n\tfont-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
