// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.gif-loader-custom {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.loader-blue {
  border: 3px solid #0051cc;
  border-top: 3px solid #ffffff;
}

.loader-white {
  border: 3px solid #ffffff;
  border-top: 3px solid #0051cc;
}

.loader-pink {
  border: 3px solid #ffffff;
  border-top: 3px solid #f56262;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,kCAAA;AACF;;AACA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AAEF;;AAAA;EACE,yBAAA;EACA,6BAAA;AAGF;;AADA;EACE,yBAAA;EACA,6BAAA;AAIF;;AAFA;EACE,yBAAA;EACA,6BAAA;AAKF;;AAFA;EACE;IACE,uBAAA;EAKF;EAHA;IACE,yBAAA;EAKF;AACF","sourcesContent":[".circle-loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n.loader {\n  border-radius: 50%;\n  animation: spin 2s linear infinite;\n}\n.gif-loader-custom{\n  width: 180px;\n  height: 180px;\n  object-fit: contain;\n}\n.loader-blue {\n  border: 3px solid #0051cc;\n  border-top: 3px solid #ffffff;\n}\n.loader-white {\n  border: 3px solid #ffffff;\n  border-top: 3px solid #0051cc;\n}\n.loader-pink {\n  border: 3px solid #ffffff;\n  border-top: 3px solid #f56262;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
