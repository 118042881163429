// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-description {
  font-size: 12px;
  font-weight: 500;
  color: #515767;
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/event-description/event-description.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,gBAAA;EACA,cAAA;AACD","sourcesContent":[".event-description {\n\tfont-size: 12px;\n\tfont-weight: 500;\n\tcolor: #515767;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
