// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pending-profile-wrapper {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1215686275);
  padding: 8px;
  border-radius: 4px;
}
.pending-profile-wrapper__img {
  width: 100%;
  object-fit: contain;
}
.pending-profile-wrapper__img__wrapper {
  position: relative;
  width: 100%;
}
.pending-profile-wrapper__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pending-profile-wrapper .opacityhalf {
  opacity: 0.5;
}

.opacityhalf {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/pending-profile/pending-profile.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EAEA,6CAAA;EACA,YAAA;EACA,kBAAA;AAAD;AACC;EACC,WAAA;EAEA,mBAAA;AAAF;AAEC;EACC,kBAAA;EACA,WAAA;AAAF;AAGC;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AADH;AAGC;EACC,YAAA;AADF;;AAMA;EACC,YAAA;AAHD","sourcesContent":[".pending-profile-wrapper {\n\twidth: 100%;\n\t// height: 100%;\n\tborder: 1px solid #0000001f;\n\tpadding: 8px;\n\tborder-radius: 4px;\n\t&__img {\n\t\twidth: 100%;\n\t\t// height: 300px;\n\t\tobject-fit: contain;\n\t}\n\t&__img__wrapper{\n\t\tposition:relative;\n\t\twidth: 100%;\n\t\t// height: 300px;\n\t}\n\t&__loader{\n\t\t position: absolute;\n\t\t top:50%;\n\t\t left: 50%;\n\t\t transform: translate(-50% , -50%);\n\t}\n\t.opacityhalf{\n\t\topacity: 0.5;\n\t}\n}\n\n\n.opacityhalf{\n opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
