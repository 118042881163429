// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-header-wrapper {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/event-header/event-header.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,oDAAA;AACD","sourcesContent":[".event-header-wrapper {\n\theight: 48px;\n\twidth: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tborder-bottom: 1px solid #0000001f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
