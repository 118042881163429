// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-profile-tag {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  width: "fit-content";
}

.spoof {
  border: 1px solid red !important;
}

.real {
  border: 1px solid green !important;
}

.profile-meta {
  background: rgb(244, 245, 245);
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 80px 85px 1fr 1fr;
  padding: 4px;
  column-gap: 8px;
}
.profile-meta__sublabel {
  font-weight: 600;
  font-size: 14px;
}
.profile-meta__inner {
  display: flex;
  flex-direction: column;
}

.profile-wrapper {
  transition: all 0.3s ease-in-out;
}

.profile-wrapper:hover {
  cursor: pointer;
  box-shadow: 10px 10px 8px 0px rgba(0, 0, 0, 0.15);
}

.break {
  word-break: break-all;
}

.image-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.event-meta-icon {
  padding: var(--zero-padding) calc(var(--base-padding) * 0.25);
  cursor: pointer;
  font-weight: 600;
  opacity: 0.5;
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/profile/profile.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,8BAAA;EACA,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,aAAA;EACA,wCAAA;EACA,YAAA;EACA,eAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;AAEJ;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,eAAA;EACA,iDAAA;AACF;;AACA;EACE,qBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AAEF;;AACA;EACE,6DAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AAEF","sourcesContent":[".event-profile-tag {\n  padding: 4px 8px;\n  border-radius: 8px;\n  font-size: 12px;\n  font-weight: 500;\n  width: \"fit-content\";\n}\n\n.spoof {\n  border: 1px solid red !important;\n}\n\n.real {\n  border: 1px solid green !important;\n}\n\n.profile-meta {\n  background: rgb(244, 245, 245);\n  width: 100%;\n  border: 1px solid rgba(0, 0, 0, 0.06);\n  border-radius: 4px;\n  display: grid;\n  grid-template-columns: 80px 85px 1fr 1fr;\n  padding: 4px;\n  column-gap: 8px;\n  &__sublabel {\n    font-weight: 600;\n    font-size: 14px;\n  }\n  &__inner {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.profile-wrapper {\n  transition: all 0.3s ease-in-out;\n}\n\n.profile-wrapper:hover {\n  cursor: pointer;\n  box-shadow: 10px 10px 8px 0px rgba(0, 0, 0, 0.15);\n}\n.break {\n  word-break: break-all;\n}\n\n.image-container {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n  align-items: center;\n}\n\n.event-meta-icon {\n  padding: var(--zero-padding) calc(var(--base-padding) * 0.25);\n  cursor: pointer;\n  font-weight: 600;\n  opacity: 0.5;\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
