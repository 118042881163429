// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-profile__header {
  display: flex;
  padding-left: 24px;
  border-bottom: 1px solid var(--color-gray-dark-100);
}
.event-profile__header_text {
  font-weight: 600;
  font-size: 13px;
  color: var(--color-text-50-light);
  padding: 8px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: -1px;
}
.event-profile__header_text__active {
  color: #0051cc !important;
  border-bottom: 2px solid #0051cc;
}`, "",{"version":3,"sources":["webpack://./src/views/events/components/header-options/header-options.scss"],"names":[],"mappings":"AACC;EACC,aAAA;EACA,kBAAA;EACA,mDAAA;AAAF;AAEC;EACC,gBAAA;EACA,eAAA;EACA,iCAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AAAF;AACE;EACC,yBAAA;EACA,gCAAA;AACH","sourcesContent":[".event-profile {\n\t&__header {\n\t\tdisplay: flex;\n\t\tpadding-left: 24px;\n\t\tborder-bottom: 1px solid var(--color-gray-dark-100);\n\t}\n\t&__header_text {\n\t\tfont-weight: 600;\n\t\tfont-size: 13px;\n\t\tcolor: var(--color-text-50-light);\n\t\tpadding: 8px;\n\t\tborder-radius: 2px;\n\t\tcursor: pointer;\n\t\tmargin-bottom: -1px;\n\t\t&__active {\n\t\t\tcolor: #0051cc !important;\n\t\t\tborder-bottom: 2px solid #0051cc;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
