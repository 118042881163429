// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
}
.layout-wrapper__header {
  height: 72px;
  width: 100%;
}
.layout-wrapper__body {
  flex-grow: 1;
  width: 100%;
}
.layout-wrapper__footer {
  height: 72px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AAAE;EACE,YAAA;EACA,WAAA;AAEJ","sourcesContent":[".layout-wrapper {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  inset: 0;\n  &__header {\n    height: 72px;\n    width: 100%;\n  }\n  &__body {\n    flex-grow: 1;\n    width: 100%;\n  }\n  &__footer {\n    height: 72px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
